export class DownloadFiles {
    today = new Date();
    date =
        this.today.getDate() < 10
            ? `0${this.today.getDate()}`
            : `${this.today.getDate()}`;
    month =
        this.today.getMonth() + 1 < 10
            ? `0${this.today.getMonth() + 1}`
            : `${this.today.getMonth() + 1}`;
    year = this.today.getFullYear();

    dateForName = `${this.date}-${this.month}-${this.year}`;

    constructor() {}

    downloadFile(data, name, noDate = false, fileType = 'xlsx') {
        const blob = new Blob([data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = noDate
            ? `${name}.${fileType}`
            : `${name} ${this.dateForName}.${fileType}`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
}
