import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-terms-of-use',
    templateUrl: './terms-of-use.component.html',
    styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
    constructor(private title: Title) {}

    ngOnInit() {
        this.title.setTitle('Terms of Use');
    }
}
