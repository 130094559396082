// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    apiUrl: 'https://vip-apidev.pathmazing.com',
    clientId: '@JP5l3UB4$Vs',
    authorization: 'Bearer dab3a9c5bfd679159d91ceb9f81f3010b1bce66e',
    // siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    recaptchaSiteKey: '6LfwhLIUAAAAAKiWHjipLRef1C19tjbW2EC9ofLv',
    recaptchaSecretKey: '6LfwhLIUAAAAAAkTwf70nTQDe1H-MThvNZjL69Or',
    idleTimeOut: 60 * 15, // set IDLE_TIMEOUT for set expire session user login in second
    auth0ClientId: 'uS1TIXcHvidCSIL1CQE6raBjYIxB2boY',
    auth0Domain: 'pathmazing.auth0.com',
    auth0Audience: 'https://vip-apidev.pathmazing.com',
    firebase: {
        apiKey: 'AIzaSyCinUbDrzt5z10wpWhKa8Qy9MJqMEfWSfc',
        authDomain: 'stars-dev-79ef1.firebaseapp.com',
        databaseURL: 'https://stars-dev-79ef1.firebaseio.com',
        projectId: 'stars-dev-79ef1',
        storageBucket: 'stars-dev-79ef1.appspot.com',
        messagingSenderId: '431099497456',
        appId: '1:431099497456:web:a044894a0e60d60b4ceb35',
        measurementId: 'G-XDZS2GB1KD',
    },
    pricePerStar: 0.10
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
