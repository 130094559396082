import { Injectable } from '@angular/core';
import { AppState } from '../reducers/app.state';
import { Profile } from '../reducers/model/profile.model';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root'
})
export class CurrentUserService {
    profile: Profile;
    constructor(private store: Store<AppState>) {
        this.store.select('profile').subscribe((res: any) => {
            this.profile = res[0];
        });
    }
}
