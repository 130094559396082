import { Injectable, Injector, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as ProdileActions from '../../shared/reducers/profile.actions';
import { AppState } from '../reducers/app.state';
import { AlertMessageService } from './alert-message.service';

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {
    constructor(
        private injector: Injector,
        private _store: Store<AppState>,
        private _alert: AlertMessageService
    ) {}

    handleError(error: any) {
        const router = this.injector.get(Router);
        // console.log(`Request URL: ${router.url}`);

        if (error instanceof HttpErrorResponse) {
            // console.log(error);
            const message = (error.error.message && error.error.message === 'jwt expired') ? 'Session Expired' : error.error.message;

            // console.log('Status Code: ', error.status);
            if (error.status === 401) {
                this._alert.alertError(message);
                this._store.dispatch(new ProdileActions.RemoveProfile(0));
                localStorage.removeItem('token');
                if (!localStorage.getItem('token')) {
                    setTimeout(() => {
                        location.reload();
                    }, 3000);
                }
            } else {
                // console.log(error, '22');

                this._alert.alertError(message);
            }
        } else {
            // console.log('An Error Occured: ', error.message);
        }
        // router.navigate(['/login']);
    }
}
