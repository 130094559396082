import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './shared/guards/AuthGuard';
import { ResetPasswordComponent } from './layouts/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './layouts/forgot-password/forgot-password.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { LoginComponent } from './layouts/login/login.component';
import { PrivacyComponent } from './modules/privacy/privacy.component';
import { CallbackComponent } from './modules/auth/callback/callback.component';
import { TermsComponent } from './modules/terms/terms.component';
import { TermsOfUseComponent } from './modules/terms-of-use/terms-of-use.component';
import { AgreeTermsComponent } from './modules/hr/agree-terms/agree-terms.component';
// import { NotFoundComponent } from "./layouts/not-found/not-found.component";

export const router: Routes = [
    {
        path: '',
        loadChildren: './layouts/main/main.module#MainModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        component: LoginLayoutComponent,
        children: [
            {
                path: '',
                component: LoginComponent
            }
        ]
    },
    {
        path: 'reset/password',
        component: LoginLayoutComponent,
        children: [
            {
                path: '',
                component: ResetPasswordComponent
            }
        ]
    },
    {
        path: 'forgot-password',
        component: LoginLayoutComponent,
        children: [
            {
                path: '',
                component: ForgotPasswordComponent
            }
        ]
    },
    {
        path: 'not-found',
        canActivate: [AuthGuard],
        loadChildren: './layouts/not-found/not-found.module#NotFoundModule'
    },
    {
        path: 'agree-terms',
        component: LoginLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: AgreeTermsComponent
            }
        ]
    },
    {
        path: 'privacy',
        component: PrivacyComponent
    },
    {
        path: 'terms-auction',
        component: TermsComponent
    },
    {
        path: 'terms',
        component: TermsOfUseComponent
    },
    {
        path: 'callback',
        component: CallbackComponent
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'not-found'
    }
];

export const appComponents = [
    LoginLayoutComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    PrivacyComponent,
    TermsComponent,
    TermsOfUseComponent,
    AgreeTermsComponent
];
export const routes: ModuleWithProviders = RouterModule.forRoot(router, {
    preloadingStrategy: PreloadAllModules
});
