import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { routes, appComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared/guards/AuthGuard';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { reducer } from './shared/reducers/profile.reducer';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GlobalErrorHandlerService } from './shared/services/global-error-handler.service';
import { Ng2IziToastModule } from 'ng2-izitoast';
import { DownloadFiles } from './shared/services/download-file';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { AngularFireModule } from '@angular/fire';

import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { CallbackComponent } from './modules/auth/callback/callback.component';
import { TermsComponent } from './modules/terms/terms.component';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
@NgModule({
    declarations: [
        AppComponent,
        appComponents,
        CallbackComponent,
        TermsComponent,

    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        routes,
        HttpClientModule,
        StoreModule.forRoot({ profile: reducer }),
        ReactiveFormsModule,
        FormsModule,
        Ng2IziToastModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        NgIdleKeepaliveModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
    ],
    providers: [
        AuthGuard,
        DownloadFiles,
        AngularFirestore,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService,
        },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.recaptchaSiteKey,
            } as RecaptchaSettings,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
