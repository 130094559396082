import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule, MatGridListModule } from '@angular/material';
import { TestDirective } from './directives/test.directive';
import { TestPipe } from './pipes/test.pipe';
import { TrimDirective } from './directives/trim.directive';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';

@NgModule({
    imports: [CommonModule, MatCardModule, MatGridListModule],
    declarations: [TestDirective, TestPipe, TrimDirective, EllipsisPipe, ShortNumberPipe],
    exports: [MatCardModule, MatGridListModule, TrimDirective, EllipsisPipe, ShortNumberPipe]
})
export class SharedModule {}
